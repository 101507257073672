import { Typography } from "@mui/material";
import { useEffect } from "react";


const ErrorPage = props => {
    useEffect(() =>{
        props.setTitleProps("Secrets");
    });
    return (
        <>
             <Typography variant="h6" gutterBottom>
                Ups! Page not found!
            </Typography>
        </>
    );
};

export default ErrorPage;