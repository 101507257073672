import { IconButton, InputBase, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = props => {
    const [searchTerm, setSearchTerm] = useState('');

    const onTextChangeProps = props.onTextChange;

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            onTextChangeProps(searchTerm);
        }, 500)
    
        return () => clearTimeout(delayDebounceFn)
      }, [searchTerm, onTextChangeProps])

    return (
        <Paper sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 250 }}>
            <InputBase
                sx={{ ml: 1 }}
                placeholder="Search"
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                disabled={props.disabled}
                inputProps={{ 'aria-label': 'search' }}
            />
            {searchTerm ? <IconButton sx={{ p: '10px' }} 
                        aria-label="clear"
                        onClick={(e) => setSearchTerm('')}>
                <ClearIcon />
            </IconButton> : 
            <IconButton sx={{ p: '10px' }} 
                    aria-label="search">
                <SearchIcon />
            </IconButton>}
        </Paper>
    );
};

export default SearchBar;