import { Button, Grid, Slide } from '@mui/material';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import React, { useEffect, useState } from "react";
import SearchBar from '../components/search.component';
import apiRequest from '../services/request';
import SectionDetail from './section-details';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DeleteConfirmDialog from '../components/deleteConfirmation.component';
import { useSnackbar } from 'notistack';
import SectionForm from './section-form';
import { formatDate } from '../utils/utils';

const Sections = props => {
    const { enqueueSnackbar } = useSnackbar();
    const [sections, setSections] = useState({items:[]});
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(5);
    const [loading, setLoading] = useState(false);
    const [sortModel, setSortModel] = useState([
        { field: 'name', sort: 'asc' },
    ]);
    const [filters, setFilters] = useState({
        searchQuery: ''
    });
    const [selectedItem, setSelectedItem] = useState();
    const [showForm, setShowForm] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState();

    const loadSections = () => {
        setLoading(true);
        const order = sortModel && sortModel.length > 0
            ? `&$orderby=${sortModel[0].field} ${sortModel[0].sort}`
            : "";

        const searchQueryFilter = filters?.searchQuery && filters.searchQuery.length > 0 && filters.searchQuery.trim().length > 0
            ? `contains(name,'${filters.searchQuery}')`
            : "";

        let filter = searchQueryFilter;
        
        if(filter && filter.length > 0)
            filter = "&$filter=" + filter;

        apiRequest(`section/paged?$select=id,created,name,backgroundColor,fontColor&$top=${pageSize}&$skip=${page*pageSize}${order}${filter}`)
        .then((response) => {
            setSections(response.data);
            setLoading(false);
        });
    };
    useEffect(() =>{
        props.setTitleProps("Sections");
    });
    useEffect(() => {
        loadSections();
    },[page,pageSize,sortModel, filters.searchQuery]);

    const onHandlePageSizeChange = (newSize) => {
        setPageSize(newSize);
    };
    const handleSortModelChange = (newModel) => {
        setSortModel(newModel);
    };
    const handleOnSearchQueryFilterChange = (searchQuery) => {
        setFilters({
            ...filters,
            searchQuery
        });
    };
    
    const handleRowSelection = (e) => {       
        setSelectedItem(e[0]);
    }
    const handleUnselectItem = () => {
        setSelectedItem(null);
    }
    const handleDeleteConfirmation = itemId => {
        setDeleteItemId(itemId);
        setConfirmOpen(true);
    }
    const handleDelete = (itemId) => {
        setLoading(true);
        apiRequest(`section/${itemId}`, {
            method: "DELETE"
        })
        .then(() => {
            enqueueSnackbar('Item successfully deleted!', {
                variant:'success',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                TransitionComponent: Slide,
                autoHideDuration: 3000
            });
            loadSections();
        }, error => {
            setLoading(false);
            enqueueSnackbar(error.response?.data?.message ?? "An unknown error occur", {
                variant:'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                TransitionComponent: Slide,
                autoHideDuration: 3000
            });
        });
    };    

    const columns = [
        { 
            field: 'actions', 
            type: 'actions',
            headerName: 'Options',
            getActions: (params) => [
                <GridActionsCellItem
                  icon={<DeleteIcon />}
                  label="Delete"
                  onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteConfirmation(params.id);
                    }}
                />,
                <GridActionsCellItem
                  icon={<EditIcon />}
                  label="Edit"
                  onClick={(e) => {
                        e.stopPropagation();
                        setSelectedItem(params.id);                        
                        setShowForm(true);
                    }}
                />,
              ]
        },
        { field: 'name', headerName: 'Name', minWidth: 300 },
        { 
            field: 'backgroundColor',
            headerName: 'Background Color',
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <div>
                        <span  style={{
                                backgroundColor: params.value,
                                color: params.row.fontColor,
                                padding: '0.4em 1em',
                                borderRadius: '8px',
                                alignItems: 'center',
                                letterSpacing: '0.8px'
                            }}>
                            {params.value}
                        </span>
                    </div>
                );
            }
        },
        { 
            field: 'fontColor',
            headerName: 'Font Color',
            minWidth: 150,
            renderCell: (params) => {
                return (
                    <div>
                        <span  style={{
                                backgroundColor: params.row.backgroundColor,
                                color: params.value,
                                padding: '0.4em 1em',
                                borderRadius: '8px',
                                alignItems: 'center',
                                letterSpacing: '0.8px'
                            }}>
                            {params.value}
                        </span>
                    </div>
                );
            }
        },
        { 
            field: 'created',
            headerName: 'Created',
            type: 'dateTime',
            minWidth: 170,
            valueGetter: (params) => {
                return formatDate(params.value)
            }
        }
    ];  

    return (
        <Grid container spacing={2}>        
            <DeleteConfirmDialog 
                    title="Delete Section?"
                    open={confirmOpen}
                    setOpen={setConfirmOpen}
                    onConfirm={() => handleDelete(deleteItemId)}>
                    Are you sure you want to delete this section?
            </DeleteConfirmDialog>    
            <Grid item xs={selectedItem || showForm ? 8 : 12} minHeight={500}>
                    <Grid container item xs={12} sx={{pb:2}}>
                        <Grid justifyContent="flex-start" alignItems="end" container item xs={8}>
                            <Grid>
                                <Button variant="contained" 
                                        onClick={() => {
                                            if(selectedItem) {
                                                setSelectedItem(null);
                                            }
                                            setShowForm(true);
                                        }}>New Section</Button>
                            </Grid>
                        </Grid>
                        <Grid justifyContent="end" container item xs alignItems="end">
                            <Grid>
                                <SearchBar onTextChange={handleOnSearchQueryFilterChange} disabled={loading}/>
                            </Grid>
                        </Grid>                        
                    </Grid>
                    <DataGrid sx={{backgroundColor: "white"}}
                        disableColumnFilter
                        disableColumnSelector
                        disableColumnMenu
                        rows={sections.items}
                        minHeight={500}
                        rowCount={sections.totalCount}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[5, 10, 20]}
                        onPageSizeChange={onHandlePageSizeChange}
                        paginationMode="server"
                        page={page}
                        onPageChange={(newPage) => setPage(newPage)}
                        sortingMode="server"
                        sortModel={sortModel}
                        onSortModelChange={handleSortModelChange}
                        loading={loading}
                        onSelectionModelChange = {handleRowSelection}
                        // filterMode="server"
                        // onFilterModelChange={handleOnFilterChange}
                    />
            </Grid>
            <Grid item style={{visibility: selectedItem || showForm ? 'visible' : 'hidden', zIndex:1000}} xs={12} md={4}>
                {!showForm 
                    ? <SectionDetail selectedItem={selectedItem} 
                                    handleUnselectItem={handleUnselectItem}
                                    handleEditItem={() =>{
                                        setShowForm(true);
                                    }}
                                    handleDeleteItem={() => {
                                        handleDeleteConfirmation(selectedItem);
                                    }}
                                />
                    : <SectionForm 
                            editingItem={selectedItem} 
                            closeForm={() => {
                                setShowForm(false)
                            }}
                            onSectionAdd={ () => {
                                enqueueSnackbar('Item successfully added!', {
                                    variant:'success',
                                    anchorOrigin: {
                                        vertical: 'top',
                                        horizontal: 'right',
                                    },
                                    TransitionComponent: Slide,
                                    autoHideDuration: 3000
                                });
                                setShowForm(false);
                                loadSections();
                            }}
                      /> 
                }
            </Grid>
        </Grid>
    );
};

export default Sections;