export const secretTypesConstants = {
	Credentials: 1,
	DatabaseCredentials: 2,
	WebCredentials: 3,
	CreditCards: 4,
	SunatCredentials: 5,
	BankAccounts: 6,
	BankDetractionAccounts: 7
};

export const secretTypes = [
{
	value: secretTypesConstants.Credentials,
	label: "Credentials",
	enumFilter: "'Credentials'"
},
{
	value: secretTypesConstants.DatabaseCredentials,
	label: "Database Credentials",
	enumFilter: "'DatabaseCredentials'"
},
{
	value: secretTypesConstants.WebCredentials,
	label: "Web Credentials",
	enumFilter: "'WebCredentials'"
},
{
	value: secretTypesConstants.CreditCards,
	label: "Tarjetas de Crédito",
	enumFilter: "'CreditCards'"
},
{
	value: secretTypesConstants.SunatCredentials,
	label: "Credenciales SUNAT",
	enumFilter: "'SunatCredentials'"
},{
	value: secretTypesConstants.BankAccounts,
	label: "Cuentas Bancarias",
	enumFilter: "'BankAccounts'"
},
{
	value: secretTypesConstants.BankDetractionAccounts,
	label: "Cuentas Bancarias Detracción",
	enumFilter: "'BankDetractionAccounts'"
}];

export const currencies = [
	{
		value: 0,
		label: "All",
		enumFilter: "'All'"
	},
	{
		value: 1,
		label: "Soles",
		enumFilter: "'Pen'"
	},
	{
		value: 2,
		label: "American Dollars",
		enumFilter: "'Usd'"
	},
	{
		value: 3,
		label: "Euros",
		enumFilter: "'Eur'"
	}];

export const cardTypes = [
	{
		value: 1,
		label: "Credit",
		enumFilter: "'Credit'"
	},
	{
		value: 2,
		label: "Debit",
		enumFilter: "'Debit'"
	}];