import { Grid, TextField } from "@mui/material";
import { formatDate } from "../utils/utils";

const DateTextField = props => {
    if(!props.value) {
        return ('');
    }
    return (
        <Grid item xs={12}>
            <TextField
                id={props.id}
                name={props.name}
                label={props.label}
                fullWidth
                variant="standard"
                value={formatDate(props.value)}
            />
        </Grid>
    );
};

export default DateTextField;