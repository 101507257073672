import { Grid, Paper, Typography } from "@mui/material";
import * as Icons from '@mui/icons-material';
import React from "react";
import { useNavigate } from 'react-router-dom';

const DashboardTotalCard = props => {
    const navigate = useNavigate();
    return (
        <Paper sx={{height:100, p:3}} style={{cursor:'pointer'}} onClick={() => navigate(`/${props.route}`)}>
            <Grid container spacing={1}>
                <Grid item xs={8}>
                    <Grid>
                        {props.title}</Grid>
                    <Grid sx={{mt:1}}>
                        <Typography variant="h5" component="h5">
                        {props.total}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={4} display="flex" justifyContent="center" flexDirection="column">
                    {React.createElement(Icons[props.icon],{fontSize:"large"})}                    
                </Grid>
            </Grid>
        </Paper>
    );
};

export default DashboardTotalCard;