import moment from "moment-timezone";
import { currencies, secretTypes, secretTypesConstants } from "./enums";
import { cardTypes } from "./enums";

export function secretTypeDisplayText(findValue){
    return secretTypes.find( ({ value }) => value === findValue )?.label ?? "-";
};
export function labelTextSecretKey(secretType) {
    return secretType == secretTypesConstants.DatabaseCredentials 
    ? "Database User" 
    : secretType == secretTypesConstants.SunatCredentials
        ? "Sunat SOL / User" 
        : secretType == secretTypesConstants.CreditCards
            ? "Card / Number" 
            : "Secret Key";
};

export function labelTextSecretValue(secretType) {
    return secretType == secretTypesConstants.DatabaseCredentials 
    ? "Database Password" 
    : secretType == secretTypesConstants.SunatCredentials
        ? "Sunat SOL / Password" 
        : secretType == secretTypesConstants.CreditCards
            ? "Card / CSV" 
            : "Secret Value";
};

export function currencyDisplayText(findValue){
    return currencies.find( ({ value }) => value === findValue )?.label ?? "-";
};
export function cardTypeDisplayText(findValue){
    return cardTypes.find( ({ value }) => value === findValue )?.label ?? "-";
};

export function isSuccessStatusCode(statusCode){
    return (statusCode >= 200 && statusCode < 300);
}

export const formatDate = (date = new Date()) => {
    return moment(`${date}Z`).format('L LT');    
};

export const copyToClipboard = (text) => {
    if(text === null || text.length === 0) return;
    
    navigator.clipboard.writeText(text);
};

export const openNewWindows = (url) => {
    window.open(url, '_blank');
};