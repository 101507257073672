import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from 'notistack';

import "./App.css"
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
    <SnackbarProvider maxSnack={3}>
        <App />
    </SnackbarProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);