import axios from "axios";
import env from "../config/env";

const omitRoutes = [
	'/refreshToken',
	'/recoveryPassword',
	'/logout',
	'/confirmAccount',
	'/resetPassword',
];
const externalRoutes = [
];
const apiRequest = function(url, options = {}) {
    let user = localStorage.getItem('x-auth-user');
	if (user) user = JSON.parse(user);
	const locale = user && user.defaultLanguage ? user.defaultLanguage : 'es-pe';

    let config = {
		...options,
		headers: {
			...options.headers,
			'Accept-Language': locale,
			clientId: env.clientId
		},
		baseURL: env.apiUrl,
		url: url
	};
    if (url.includes('/auth') && !url.includes('changePassword') && !url.includes('logout')) {
		if (omitRoutes.every((str) => !url.includes(str))) {
			const { username, password } = options.data;
			config.headers.Authorization = `Basic ${btoa(`${username}:${password}`)}`;
			delete config.data;
		}        
	} else if (externalRoutes.every((str) => !url.includes(str))) {
		const token = localStorage.getItem('x-auth-token');
		if (token) {
			const { tokenType, accessToken } = JSON.parse(token);
			config.headers.Authorization = `${tokenType} ${accessToken}`;
		} else {
            window.location.reload();
        }
	}
    return axios(config)
	.then(response => {
		return response;
	})
	.catch(error => {
        if (error.response) {
           if(error.response.status === 401) {
			   try {					
					const token = JSON.parse(localStorage.getItem("x-auth-token"));
					localStorage.removeItem("x-auth-token");
					if(token) {
						const { accessToken, refreshToken } = (token);
						if(accessToken && refreshToken) {
							return apiRequest("/auth/refreshToken", {
								method: "POST",
								data: { accessToken, refreshToken }
							}).then(response => {
								localStorage.setItem("x-auth-token", JSON.stringify(response.data));
								options.reloadIfFailed = true;
								return apiRequest(url, options);
							});
						}
					}					
			   } catch(e){
				   //console.log(e);
			   }
		   } else {
			   if(options.reloadIfFailed)
					window.location.reload();
		   }
        }
		return Promise.reject(error);
	});
}

export default apiRequest;