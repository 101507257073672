import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useState } from 'react';
import apiRequest from '../services/request';
import { useEffect } from 'react';
import LogoutIcon from '@mui/icons-material/Logout';
import { Divider } from '@mui/material';

const UserMenu = props => {
    const [companies, setCompanies] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        props.handleLogoutProps();
    };

    const changeCompany = (access) => {
        props.handleChangeCompanyProps(access);
    };

    useEffect(() => {
        apiRequest(`company/allowed?$select=id,name,commercialName,identity`)
        .then(response => {
            setCompanies(response.data);
        });
    }, []);


    if(companies == null || !companies || companies.length <= 1) {
        return (
            <IconButton color="inherit" onClick={handleLogout}>
                <LogoutIcon />
            </IconButton> 
        );
    }
    return (
        <div>
              <IconButton
                size="large"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >                
                <Typography
                    component="h6"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1, mr: 2 }}>
                    {companies.filter(s => s.company.id == props.currentUserProps.companyId)[0].company.commercialName}
                </Typography>
                <AccountCircle /> 
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
                
                <Divider />

                {companies.map((access) => {
                    if(props.currentUserProps.companyId == access.company.id)
                        return <MenuItem key={access.company.id} disabled>{access.company.commercialName}</MenuItem>    

                    return <MenuItem key={access.company.id} onClick={() => changeCompany(access)}>{access.company.commercialName}</MenuItem>
                })}
              </Menu>
        </div>
    );
};

export default UserMenu;