import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthService from '../services/AuthService';
import { CircularProgress, Slide } from '@mui/material';
import { isSuccessStatusCode } from "../utils/utils";
import { useSnackbar } from 'notistack';
import MicrosoftLogin from 'react-microsoft-login';
import GoogleLogin from 'react-google-login';

import env from '../config/env';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © ACE VAULT '}
      {new Date().getFullYear()}
      {' - Powered By '}
      <Link color="inherit" href="https://aceroyalty.com/" target="_blank">
        ACEROYALTY
      </Link>{' '}
    </Typography>
  );
}

const theme = createTheme();

const Login = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userPass, setuserPass] = useState({username:"",password:""});
  const [loading, setLoading] = useState(false);
  const [azureLoading, setAzureLoading] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);

  const urlParams = new URL(window.location.href);

  const onChangeuserPass = e => {
    setuserPass({
      ...userPass,
      [e.target.name]: e.target.value,
    })
  }

  const responseGoogle = (response) => {
    console.log(response);
  }

  useEffect(() => {
    if (urlParams.hash.includes('id_token=') && urlParams.hash.includes('state=')) {
      const [hash] = urlParams.hash.split('&');
      var splited = hash.split('=');
      const msToken = splited[splited.length-1];
      if(msToken) {
        setAzureLoading(true);

        AuthService.LoginAzureAd({ password: msToken, username: 'ad' }).then(response => {
          setAzureLoading(false);
          if(isSuccessStatusCode(response.status)) {
              window.location.reload();
          } else {
            enqueueSnackbar(response?.data?.message ?? response?.response?.data?.message ?? "An unknown error occur", {
              variant:'error',
              anchorOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
              },
              TransitionComponent: Slide,
              autoHideDuration: 3000
          });
          }
        });
      }      
    }
  }, [urlParams]);

  const handleSubmit = (event) => {
    event.preventDefault();
    
    setLoading(true);

    AuthService.Login(userPass).then(response => {
      setLoading(false);
      if(isSuccessStatusCode(response.status)) {
          window.location.reload();
      } else {
        enqueueSnackbar(response?.data?.message ?? response?.response?.data?.message ?? "An unknown error occur", {
          variant:'error',
          anchorOrigin: {
              vertical: 'top',
              horizontal: 'right',
          },
          TransitionComponent: Slide,
          autoHideDuration: 3000
      });
      }
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://source.unsplash.com/random)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                name="username"
                onChange={onChangeuserPass}
                value={userPass.username}
                autoComplete="username"
                disabled={loading || azureLoading}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                onChange={onChangeuserPass}
                value={userPass.password}
                id="password"
                disabled={loading || azureLoading}
                autoComplete="current-password"
              />
              
              <Button
                type="submit"
                fullWidth
                disabled={!userPass.username || !userPass.password || loading || azureLoading}
                variant="contained"
                sx={{ mt: 3, mb: 2, height:50 }}
              >
                {loading ? <CircularProgress color="secondary" sx={{mr:2}}/> : ''} Sign In
              </Button>

              <MicrosoftLogin
                  forceRedirectStrategy
                  graphScopes={['user.read']}
                  redirectUri={window.location.origin}
                  clientId={env.azureAppClientId}                  
                >
                <Button type="reset" 
                        fullWidth
                        variant="contained"
                        sx={{ mb: 2, height:50 }}>
                   {azureLoading ? <CircularProgress color="secondary" sx={{mr:2}}/> : ''}  AZURE AD
                </Button>
              </MicrosoftLogin>
              {/* <GoogleLogin
                clientId={env.googleAuthClientId}
                buttonText="Google"
                fullWidth
                onSuccess={responseGoogle}
                onFailure={responseGoogle}
                scope="profile email"
                uxMode="redirect"
                redirectUri="http://localhost:3000"
              >
                
              </GoogleLogin> */}
              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Login;