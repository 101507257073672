import { Button, CircularProgress, Divider, Grid, IconButton, InputAdornment, Paper, Skeleton, Slide, TextField, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import apiRequest from "../services/request";
import { cardTypeDisplayText, copyToClipboard, currencyDisplayText, labelTextSecretKey, labelTextSecretValue, openNewWindows, secretTypeDisplayText } from "../utils/utils";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DateTextField from "../components/dateTexField.component";
import CloseIcon from '@mui/icons-material/Close';
import CopyIcon from '@mui/icons-material/CopyAll';
import { useSnackbar } from 'notistack';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { secretTypesConstants } from "../utils/enums";

const SecretDetail = props => {
    const { enqueueSnackbar } = useSnackbar();
    const [selectedItem, setSelectedItem] = useState();
    const [loading, setLoading] = useState();
    const [showSecretKey, setShowSecretKey] = useState({
        showSecret: false,
        secretDecodedValue: "",
        loadingDecodedValue: false
    });
    const [showSecretValue, setShowSecretValue] = useState({
        showSecret: false,
        secretDecodedValue: "",
        loadingDecodedValue: false
    });

    const secretItemId = props.selectedItem;

    const showTextCopied = () => {
        enqueueSnackbar('Text successfully copied!', {
            variant:'info',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'right',
            },
            TransitionComponent: Slide,
            autoHideDuration: 1000
        });
    };
    useEffect(() => {
        setShowSecretKey({
            showSecret: false,
            secretDecodedValue: "",
            loadingDecodedValue: false
        });
        setShowSecretValue({
            showSecret: false,
            secretDecodedValue: "",
            loadingDecodedValue: false
        });
        if(secretItemId) {
            setLoading(true);
            apiRequest(`secretItem/${secretItemId}?$expand=userCreator($select=fullName),userModifier($select=fullName),section($select=id,name),client($select=id,name)`)
            .then(response => {
                setSelectedItem(response.data);

                setLoading(false);
            });
        }
    }, [secretItemId]);

    useEffect(() => {
        return () => {
            setShowSecretKey({
                showSecret: false,
                secretDecodedValue: "",
                loadingDecodedValue: false
            });
            setShowSecretValue({
                showSecret: false,
                secretDecodedValue: "",
                loadingDecodedValue: false
            });
        };
    }, []);

    const handleCopyText = (text) => {
        copyToClipboard(text);
        showTextCopied();      
    };
    const handleCopySecretKey = () => {
        if(showSecretKey.secretDecodedValue) {
            handleCopyText(showSecretKey.secretDecodedValue);
        } else {
            setShowSecretKey({
                ...showSecretKey,
                loadingDecodedValue: true
            });
            apiRequest(`secretItem/showSecretKey?secretId=${secretItemId}`)
            .then(response => {
                setShowSecretKey({
                    ...showSecretKey,
                    secretDecodedValue:response.data.secretKey,
                    loadingDecodedValue: false
                });

                handleCopyText(response.data.secretKey);
            });
        }        
    };
    const handleShowSecretKey = () => {
        if(showSecretKey.showSecret || showSecretKey.secretDecodedValue) {
            setShowSecretKey({
                ...showSecretKey,
                showSecret: !showSecretKey.showSecret,
                loadingDecodedValue: false
            });
        } else {
            setShowSecretKey({
                ...showSecretKey,
                loadingDecodedValue: true
            });
            apiRequest(`secretItem/showSecretKey?secretId=${secretItemId}`)
            .then(response => {
                setShowSecretKey({
                    ...showSecretKey,
                    showSecret: !showSecretKey.showSecret,
                    secretDecodedValue:response.data.secretKey,
                    loadingDecodedValue: false
                });
            });
        }   
    };
    const handleCopySecretValue = () => {
        if(showSecretValue.secretDecodedValue) {
            handleCopyText(showSecretValue.secretDecodedValue);
        } else {
            setShowSecretValue({
                ...showSecretValue,
                loadingDecodedValue: true
            });
            apiRequest(`secretItem/showSecretValue?secretId=${secretItemId}`)
            .then(response => {
                setShowSecretValue({
                    ...showSecretValue,
                    secretDecodedValue:response.data.secretValue,
                    loadingDecodedValue: false
                });

                handleCopyText(response.data.secretValue);
            });
        }   
    };

    const handleShowSecretValue = () => {
        if(showSecretValue.showSecret || showSecretValue.secretDecodedValue) {
            setShowSecretValue({
                ...showSecretValue,
                showSecret: !showSecretValue.showSecret,
                loadingDecodedValue: false
            });
        } else {
            setShowSecretValue({
                ...showSecretValue,
                loadingDecodedValue: true
            });
            apiRequest(`secretItem/showSecretValue?secretId=${secretItemId}`)
            .then(response => {
                setShowSecretValue({
                    ...showSecretValue,
                    showSecret: !showSecretValue.showSecret,
                    secretDecodedValue:response.data.secretValue,
                    loadingDecodedValue: false
                });
            });
        }   
    };

    if(!selectedItem || loading) {
        return (
            <>
                <Skeleton height={80} width={250}/>
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} width={120} animation="wave" />
            </>
        );
    }
    return (
        <Paper sx={{p:2}}>        
           <Grid container spacing={3}>
                <Grid item xs={12} flex="inline-flex">
                    <Grid container>
                        <Grid item xs={8}>
                            <Typography variant="h6" gutterBottom>
                                Details
                            </Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Button style={{
                                float:'right'
                            }} onClick={props.handleUnselectItem}>
                                <CloseIcon />
                            </Button>
                        </Grid>
                    </Grid>                    
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="id"
                        name="id"
                        label="Identifier"
                        fullWidth
                        variant="standard"
                        value={selectedItem.id}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="type"
                        name="type"
                        label="Type"
                        fullWidth
                        variant="standard"
                        value={secretTypeDisplayText(selectedItem.type)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="section"
                        name="section"
                        label="Section"
                        fullWidth
                        variant="standard"
                        value={selectedItem.section.name}
                    />
                </Grid>
                {selectedItem.client ? <Grid item xs={12}>
                    <TextField
                        id="client"
                        name="client"
                        label="Client"
                        fullWidth
                        variant="standard"
                        value={selectedItem.client?.name}
                    />
                </Grid> : ''}
                <Grid item xs={12}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        fullWidth
                        variant="standard"
                        value={selectedItem.name}

                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                    <IconButton onClick={() => {
                                        handleCopyText(selectedItem.name);
                                    }}>
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            )
                          }}
                    />
                </Grid>                
                {selectedItem.host && selectedItem.type != secretTypesConstants.CreditCards ? <Grid item xs={12}>
                    <TextField
                        id="host"
                        name="host"
                        label="Host"
                        fullWidth
                        variant="standard"
                        value={selectedItem.host}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                    {selectedItem.host.startsWith("http") ? 
                                        <Tooltip title="Open Link">
                                            <IconButton onClick={() => {
                                                openNewWindows(selectedItem.host);
                                            }}>
                                                <OpenInNewIcon />
                                            </IconButton>
                                        </Tooltip>
                                        : null}
                                        <Tooltip title="Copy">
                                            <IconButton onClick={() => {
                                                handleCopyText(selectedItem.host);
                                            }}>
                                                <CopyIcon />
                                            </IconButton>
                                        </Tooltip>
                              </InputAdornment>
                            )
                          }}
                    />
                </Grid> : ''}

                {(selectedItem?.type == secretTypesConstants.CreditCards ||
                  selectedItem?.type == secretTypesConstants.BankAccounts ||
                  selectedItem?.type == secretTypesConstants.BankDetractionAccounts) ? <Grid item xs={12}>
                    <TextField
                        id="currency"
                        name="currency"
                        label="Currency"
                        multiline
                        fullWidth
                        variant="standard"
                        value={currencyDisplayText(selectedItem.currency)}
                    />
                </Grid> : ''}                

                {(selectedItem?.type == secretTypesConstants.BankAccounts ||
                  selectedItem?.type == secretTypesConstants.BankDetractionAccounts) ? <Grid item xs={12}>
                    <TextField
                        id="accountNumber"
                        name="accountNumber"
                        label="Account Number"
                        multiline
                        fullWidth
                        variant="standard"
                        value={selectedItem.accountNumber}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                        <IconButton onClick={() => {
                                            handleCopyText(selectedItem.accountNumber);
                                        }}>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                              </InputAdornment>
                            )
                        }}
                    />
                </Grid> : ''}

                {selectedItem?.type == secretTypesConstants.BankAccounts && selectedItem.accountNumberCCI ? <Grid item xs={12}>
                    <TextField
                        id="accountNumberCCI"
                        name="accountNumberCCI"
                        label="Account Number CCI"
                        multiline
                        fullWidth
                        variant="standard"
                        value={selectedItem.accountNumberCCI}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                        <IconButton onClick={() => {
                                            handleCopyText(selectedItem.accountNumberCCI);
                                        }}>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                              </InputAdornment>
                            )
                        }}
                    />
                </Grid> : ''}

                {selectedItem?.type == secretTypesConstants.BankAccounts && selectedItem.internationalInformationBank ? <Grid item xs={12}>
                    <TextField
                        id="internationalInformationBank"
                        name="internationalInformationBank"
                        label="International Information Bank"
                        multiline
                        fullWidth
                        variant="standard"
                        value={selectedItem.internationalInformationBank}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                        <IconButton onClick={() => {
                                            handleCopyText(selectedItem.internationalInformationBank);
                                        }}>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                              </InputAdornment>
                            )
                        }}
                    />
                </Grid> : ''}

                {selectedItem?.type == secretTypesConstants.BankAccounts && selectedItem.internationalInformationAddress ? <Grid item xs={12}>
                    <TextField
                        id="internationalInformationAddress"
                        name="internationalInformationAddress"
                        label="International Information Address"
                        multiline
                        fullWidth
                        variant="standard"
                        value={selectedItem.internationalInformationAddress}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                        <IconButton onClick={() => {
                                            handleCopyText(selectedItem.internationalInformationAddress);
                                        }}>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                              </InputAdornment>
                            )
                        }}
                    />
                </Grid> : ''}

                {selectedItem?.type == secretTypesConstants.BankAccounts && selectedItem.internationalInformationSwift ? <Grid item xs={12}>
                    <TextField
                        id="internationalInformationSwift"
                        name="internationalInformationSwift"
                        label="International Information Swift"
                        multiline
                        fullWidth
                        variant="standard"
                        value={selectedItem.internationalInformationSwift}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                        <IconButton onClick={() => {
                                            handleCopyText(selectedItem.internationalInformationSwift);
                                        }}>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                              </InputAdornment>
                            )
                        }}
                    />
                </Grid> : ''}

                {selectedItem?.type == secretTypesConstants.SunatCredentials ? <Grid item xs={12}>
                    <TextField
                        id="sunatInformationRUC"
                        name="sunatInformationRUC"
                        label="Sunat RUC"
                        multiline
                        fullWidth
                        variant="standard"
                        value={selectedItem.sunatInformationRUC}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                        <IconButton onClick={() => {
                                            handleCopyText(selectedItem.sunatInformationRUC);
                                        }}>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                              </InputAdornment>
                            )
                        }}
                    />
                </Grid> : ''}

                {selectedItem?.type == secretTypesConstants.CreditCards ? <Grid item xs={12}>
                    <TextField
                        id="cardType"
                        name="cardType"
                        label="Card Type"
                        multiline
                        fullWidth
                        variant="standard"
                        value={cardTypeDisplayText(selectedItem.cardType)}
                    />
                </Grid> : ''}

                {selectedItem.secretKey ? <Grid item xs={12}>
                    <TextField
                        id="secretKey"
                        name="secretKey"
                        label={labelTextSecretKey(selectedItem.type)}
                        fullWidth
                        variant="standard"
                        type={!showSecretKey.showSecret ? 'password' : 'text'}
                        value={!showSecretKey.showSecret ? selectedItem.secretKey : showSecretKey.secretDecodedValue}

                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {showSecretKey.loadingDecodedValue ?
                                    <CircularProgress sx={{ml:1}} size={20}/>
                                : null}                                
                                <Tooltip title={!showSecretKey.showSecret ? "Show" : "Hide"}>
                                    <IconButton onClick={handleShowSecretKey}>
                                        {!showSecretKey.showSecret ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Copy">
                                    <IconButton onClick={handleCopySecretKey}>
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            )
                          }}
                    />
                </Grid> : ''}
                {selectedItem.secretValue ? <Grid item xs={12}>
                    <TextField
                        id="secretValue"
                        name="secretValue"
                        label={labelTextSecretValue(selectedItem.type)}
                        fullWidth
                        variant="standard"
                        type={!showSecretValue.showSecret ? 'password' : 'text'}
                        value={!showSecretValue.showSecret ? selectedItem.secretValue : showSecretValue.secretDecodedValue}

                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">                                
                                {showSecretValue.loadingDecodedValue ?
                                    <CircularProgress sx={{ml:1}} size={20}/>
                                : null}                                
                                <Tooltip title={!showSecretValue.showSecret ? "Show" : "Hide"}>
                                    <IconButton onClick={handleShowSecretValue}>
                                        {!showSecretValue.showSecret ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title="Copy">
                                    <IconButton onClick={handleCopySecretValue}>
                                        <CopyIcon />
                                    </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            )
                          }}
                    />
                </Grid> : ''}

                {selectedItem?.type == secretTypesConstants.CreditCards ? <Grid item xs={12}>
                    <TextField
                        id="cardExpiration"
                        name="cardExpiration"
                        label="Card Expiration"
                        multiline
                        fullWidth
                        variant="standard"
                        value={selectedItem.cardExpiration}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                        <IconButton onClick={() => {
                                            handleCopyText(selectedItem.cardExpiration);
                                        }}>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                              </InputAdornment>
                            )
                        }}
                    />
                </Grid> : ''}

                {selectedItem?.type == secretTypesConstants.CreditCards && selectedItem.userName ? <Grid item xs={12}>
                    <TextField
                        id="userName"
                        name="userName"
                        label="User Name"
                        multiline
                        fullWidth
                        variant="standard"
                        value={selectedItem.userName}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                        <IconButton onClick={() => {
                                            handleCopyText(selectedItem.userName);
                                        }}>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                              </InputAdornment>
                            )
                        }}
                    />
                </Grid> : ''}

                {selectedItem?.type == secretTypesConstants.CreditCards && selectedItem.userLastName ? <Grid item xs={12}>
                    <TextField
                        id="userLastName"
                        name="userLastName"
                        label="User Last Name"
                        multiline
                        fullWidth
                        variant="standard"
                        value={selectedItem.userLastName}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                        <IconButton onClick={() => {
                                            handleCopyText(selectedItem.userLastName);
                                        }}>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                              </InputAdornment>
                            )
                        }}
                    />
                </Grid> : ''}

                {selectedItem?.type == secretTypesConstants.CreditCards && selectedItem.userEmail ? <Grid item xs={12}>
                    <TextField
                        id="userEmail"
                        name="userEmail"
                        label="User Email"
                        multiline
                        fullWidth
                        variant="standard"
                        value={selectedItem.userEmail}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                        <IconButton onClick={() => {
                                            handleCopyText(selectedItem.userEmail);
                                        }}>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                              </InputAdornment>
                            )
                        }}
                    />
                </Grid> : ''}
                
                {selectedItem.notes ? <Grid item xs={12}>
                    <TextField
                        id="notes"
                        name="notes"
                        label="Notes"
                        multiline
                        fullWidth
                        variant="standard"
                        value={selectedItem.notes}
                        InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip title="Copy">
                                        <IconButton onClick={() => {
                                            handleCopyText(selectedItem.userEmail);
                                        }}>
                                            <CopyIcon />
                                        </IconButton>
                                    </Tooltip>
                              </InputAdornment>
                            )
                        }}
                    />
                </Grid> : ''}

                <DateTextField id="created" name="created" label="Created" value={selectedItem.created}/>
                <DateTextField id="updated" name="updated" label="Updated" value={selectedItem.updated}/>

                {selectedItem.userCreator ? <Grid item xs={12}>
                    <TextField
                        id="userCreator"
                        name="userCreator"
                        label="User Creator"
                        fullWidth
                        variant="standard"
                        value={selectedItem.userCreator?.fullName}
                    />
                </Grid> : ''}

                {selectedItem.userModifier ? <Grid item xs={12}>
                    <TextField
                        id="userModifier"
                        name="userModifier"
                        label="User Modifier"
                        fullWidth
                        variant="standard"
                        value={selectedItem.userModifier?.fullName}
                    />
                </Grid> : ''}

                <Divider />
                <Grid item xs={12}>
                    <Button onClick={props.handleUnselectItem} variant="contained">Close</Button>
                    <Button onClick={props.handleEditItem} variant="contained" sx={{ml:1}} >Edit</Button>
                    <Button onClick={props.handleDeleteItem} variant="contained" sx={{ml:1}} color="error">Delete</Button>
                </Grid>
            </Grid>
        </Paper>
    );
};
export default SecretDetail;