import { Button, Divider, Grid, Paper, TextField, Typography, CircularProgress, Slide, Skeleton } from "@mui/material";

import React, { useState, useEffect } from "react";
import AutocompleteServerList from "../components/serverAutocomplete.component";
import apiRequest from "../services/request";
import { isSuccessStatusCode, labelTextSecretKey, labelTextSecretValue } from "../utils/utils";
import { useSnackbar } from 'notistack';
import SecretTypeSelect from "../components/secretTypeSelect.component";
import CardTypeSelect from "../components/cardTypeSelect.component";
import CurrencySelect from "../components/currencySelect.component";
import CloseIcon from '@mui/icons-material/Close';
import { secretTypesConstants } from "../utils/enums";

const SecretForm = props => {    
    const {enqueueSnackbar} = useSnackbar();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState();
    // const formReducer = (state, event) => {
    //     setErrors({
    //         ...errors,
    //         [event.target.name]: null
    //     })
    //     return {
    //       ...state,
    //       [event.target.name]: event.target.value
    //     }
    // };

    const [submitting, setSubmitting] = useState(false);
    const [item, setItem] = useState({});
    //const [formData, setFormData] = useReducer(formReducer, {});   
    
    const editingItem = props.editingItem;

    useEffect(() => {
        if(editingItem) {
            setLoading(true);
            apiRequest(`secretItem/${editingItem}?$expand=section($select=id,name),client($select=id,name)`)
            .then(response => {                
                let editingItemData = {
                    ...response.data,
                    secretKey: '',
                    secretValue: ''
                };                

                if(response.data.secretKey && response.data.secretKey.length > 0) {
                    apiRequest(`secretItem/showSecretKey?secretId=${editingItem}`).then(responseSecretKey => {
                        editingItemData['secretKey'] = responseSecretKey.data.secretKey;

                        setItem(editingItemData);
                    });
                }
                if(response.data.secretValue && response.data.secretValue.length > 0) {
                    apiRequest(`secretItem/showSecretValue?secretId=${editingItem}`).then(responseSecretValue => {
                        editingItemData['secretValue'] = responseSecretValue.data.secretValue;

                        setItem(editingItemData);
                    });
                }

                setItem(editingItemData);
                
                setLoading(false);
            });
        }
    }, [editingItem]);
    
    const handleSubmit = (event) => {
        event.preventDefault();

        setSubmitting(true);
        apiRequest("secretItem", {
            method: !editingItem ? "POST" : "PUT",
            data: item
            // data: {
            //     ...item,
            //     ...formData
            // }
        })
        .then(response => {
            if(isSuccessStatusCode(response.status)) {
                props.onSecretAdd();
            }
            setSubmitting(false);
        }, error => {
            enqueueSnackbar(error.response.data.message, {
                variant:'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                TransitionComponent: Slide,
                autoHideDuration: 3000
            });

            try{
                var validationErrors = error.response.data.validationErrors;
                if(validationErrors instanceof Array){
                    for (let i = 0; i < validationErrors.length; i++) {
                        const element = validationErrors[i];
                        let errorMessages = "";
                        element.errorMessages.forEach(em => {
                            errorMessages += em;
                        });
                        setErrors({
                            ...errors,
                            [element.field]: errorMessages
                        })
                    }
                }
            } catch(e){
                //
            }
            
            setSubmitting(false);
        });
    }

    if(loading) {
        return (
            <>
                <Skeleton height={80} width={250}/>
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} width={120} animation="wave" />
            </>
        );
    }
    return (
        <form onSubmit={handleSubmit}>
            <Paper sx={{p:2}}>        
                <Grid container spacing={3}>
                <Grid item xs={12} flex="inline-flex">
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography variant="h6" gutterBottom>
                                    {editingItem ? "Edit" : "Add"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button style={{
                                        float:'right'
                                    }} onClick={props.closeForm}>
                                        <CloseIcon />
                                    </Button>
                                </Grid>
                            </Grid>                    
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <SecretTypeSelect   label="Type"
                                                selectId="type"
                                                required={true}
                                                defaultSelectedItem={item?.type}
                                                setSelectedItem={(selected) => {
                                                    setItem({
                                                        ...item,
                                                        type: selected?.value
                                                    })
                                                }} />
                            <span style={{ color: "red" }}>{errors["type"]}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteServerList entityName="section"
                                        selectQuery="?$select=id,name"
                                        defaultSelectedItem={item?.section}
                                        label="Section"
                                        selectId="section"
                                        valueProperty="id"
                                        displayProperty="name"
                                        required={true}
                                        setSelectedItem={(event, selected) => {
                                            setItem({
                                                ...item,
                                                sectionId: selected?.id
                                            })
                                        }} />
                            <span style={{ color: "red" }}>{errors["sectionId"]}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <AutocompleteServerList entityName="client"
                                        selectQuery="?$select=id,name"
                                        defaultSelectedItem={item?.client}
                                        label="Client"
                                        selectId="client"
                                        id="name"
                                        name="name"
                                        valueProperty="id"
                                        displayProperty="name"
                                        setSelectedItem={(event, selected) => {
                                            setItem({
                                                ...item,
                                                clientId: selected?.id
                                            })
                                        }}/>
                                <span style={{ color: "red" }}>{errors["clientId"]}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="name"
                                name="name"
                                label="Name"
                                fullWidth
                                variant="standard"
                                value={item?.name}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        [e.target.name]: e.target.value
                                    })
                                }}
                                // onChange={setFormData}
                            />
                            <span style={{ color: "red" }}>{errors["name"]}</span>
                        </Grid>
                        {item?.type != secretTypesConstants.CreditCards ? <Grid item xs={12}>
                            <TextField
                                required={item?.type == secretTypesConstants.DatabaseCredentials}
                                id="host"
                                name="host"
                                label={(item?.type == secretTypesConstants.BankAccounts || item?.type == secretTypesConstants.BankDetractionAccounts) ? "Bank Url" : "Host"}
                                fullWidth
                                variant="standard"
                                value={item?.host}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        [e.target.name]: e.target.value
                                    })
                                }}
                                //onChange={setFormData}
                            />
                            <span style={{ color: "red" }}>{errors["host"]}</span>
                        </Grid> : ''}
                        
                        {(item?.type == secretTypesConstants.CreditCards ||
                          item?.type == secretTypesConstants.BankAccounts ||
                          item?.type == secretTypesConstants.BankDetractionAccounts) ? 
                            <Grid item xs={12}>
                                <CurrencySelect   label="Currency"
                                                    selectId="currency"
                                                    defaultSelectedItem={item?.currency}
                                                    setSelectedItem={(selected) => {
                                                        setItem({
                                                            ...item,
                                                            currency: selected?.value
                                                        })
                                                    }} />
                                <span style={{ color: "red" }}>{errors["currency"]}</span>
                            </Grid> : ''}

                        {(item?.type == secretTypesConstants.BankAccounts ||
                          item?.type == secretTypesConstants.BankDetractionAccounts) ? 
                          <Grid item xs={12}><TextField
                                    required
                                    id="accountNumber"
                                    name="accountNumber"
                                    label="Account Number"
                                    fullWidth
                                    variant="standard"
                                    value={item?.accountNumber}
                                    onChange={(e) => {
                                        setItem({
                                            ...item,
                                            [e.target.name]: e.target.value
                                        })
                                    }}
                                /> 
                                <span style={{ color: "red" }}>{errors["accountNumber"]}</span>
                            </Grid>: ''}

                        {(item?.type == secretTypesConstants.BankAccounts) ? 
                          <Grid item xs={12}><TextField
                                    id="accountNumberCCI"
                                    name="accountNumberCCI"
                                    label="Account Number CCI"
                                    fullWidth
                                    variant="standard"
                                    value={item?.accountNumberCCI}
                                    onChange={(e) => {
                                        setItem({
                                            ...item,
                                            [e.target.name]: e.target.value
                                        })
                                    }}
                                /> 
                                <span style={{ color: "red" }}>{errors["accountNumberCCI"]}</span>
                            </Grid> : ''}

                            {(item?.type == secretTypesConstants.BankAccounts) ? 
                                <Grid item xs={12}>
                                    <TextField
                                            id="internationalInformationBank"
                                            name="internationalInformationBank"
                                            label="International Information Bank"
                                            fullWidth
                                            variant="standard"
                                            value={item?.internationalInformationBank}
                                            onChange={(e) => {
                                                setItem({
                                                    ...item,
                                                    [e.target.name]: e.target.value
                                                })
                                            }}
                                        /> 
                                        <span style={{ color: "red" }}>{errors["internationalInformationBank"]}</span>
                                    </Grid> : ''}

                            {(item?.type == secretTypesConstants.BankAccounts) ? 
                                <Grid item xs={12}>
                                    <TextField
                                            id="internationalInformationAddress"
                                            name="internationalInformationAddress"
                                            label="International Information Address"
                                            fullWidth
                                            variant="standard"
                                            value={item?.internationalInformationAddress}
                                            onChange={(e) => {
                                                setItem({
                                                    ...item,
                                                    [e.target.name]: e.target.value
                                                })
                                            }}
                                        /> 
                                        <span style={{ color: "red" }}>{errors["internationalInformationAddress"]}</span>
                                    </Grid> : ''}

                            {(item?.type == secretTypesConstants.BankAccounts) ? 
                                <Grid item xs={12}>
                                    <TextField
                                            id="internationalInformationSwift"
                                            name="internationalInformationSwift"
                                            label="International Information Swift"
                                            fullWidth
                                            variant="standard"
                                            value={item?.internationalInformationSwift}
                                            onChange={(e) => {
                                                setItem({
                                                    ...item,
                                                    [e.target.name]: e.target.value
                                                })
                                            }}
                                        /> 
                                        <span style={{ color: "red" }}>{errors["internationalInformationSwift"]}</span>
                                    </Grid> : ''}
                        
                        {(item?.type == secretTypesConstants.SunatCredentials) ? 
                          <Grid item xs={12}><TextField
                                    id="sunatInformationRUC"
                                    name="sunatInformationRUC"
                                    label="Sunat RUC"
                                    fullWidth
                                    required
                                    variant="standard"
                                    value={item?.sunatInformationRUC}
                                    onChange={(e) => {
                                        setItem({
                                            ...item,
                                            [e.target.name]: e.target.value
                                        })
                                    }}
                                /> 
                                <span style={{ color: "red" }}>{errors["sunatInformationRUC"]}</span>
                            </Grid> : ''}

                        {item?.type == secretTypesConstants.CreditCards ? 
                            <Grid item xs={12}>
                                <CardTypeSelect   label="CardType"
                                required
                                                    selectId="cardType"
                                                    defaultSelectedItem={item?.cardType}
                                                    setSelectedItem={(selected) => {
                                                        setItem({
                                                            ...item,
                                                            cardType: selected?.value
                                                        })
                                                    }} />
                                <span style={{ color: "red" }}>{errors["cardType"]}</span>
                            </Grid> : ''}
                        

                        {(item?.type != secretTypesConstants.BankAccounts  &&
                          item?.type != secretTypesConstants.BankDetractionAccounts )
                            ? <Grid item xs={12}>
                            <TextField
                                required={item?.type == secretTypesConstants.SunatCredentials || 
                                          item?.type == secretTypesConstants.DatabaseCredentials}
                                id="secretKey"
                                name="secretKey"
                                label={labelTextSecretKey(item?.type)}
                                fullWidth
                                variant="standard"
                                value={item?.secretKey}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        [e.target.name]: e.target.value
                                    })
                                }}
                                //onChange={setFormData}
                            />
                            <span style={{ color: "red" }}>{errors["secretKey"]}</span>
                        </Grid> : ''}

                        {(item?.type != secretTypesConstants.BankAccounts  &&
                          item?.type != secretTypesConstants.BankDetractionAccounts )
                            ? <Grid item xs={12}>
                            <TextField
                                required
                                id="secretValue"
                                name="secretValue"
                                label={labelTextSecretValue(item?.type)}
                                fullWidth
                                variant="standard"
                                value={item?.secretValue}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        [e.target.name]: e.target.value
                                    })
                                }}
                                //onChange={setFormData}
                            />
                            <span style={{ color: "red" }}>{errors["secretValue"]}</span>
                        </Grid> : ''}

                        
                        {item?.type == secretTypesConstants.CreditCards
                            ? <Grid item xs={12}>
                            <TextField
                                required
                                id="cardExpiration"
                                name="cardExpiration"
                                label="Card Expiration"
                                fullWidth
                                variant="standard"
                                value={item?.cardExpiration}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        [e.target.name]: e.target.value
                                    })
                                }}
                                //onChange={setFormData}
                            />
                            <span style={{ color: "red" }}>{errors["cardExpiration"]}</span>
                        </Grid> : ''}

                        {item?.type == secretTypesConstants.CreditCards
                            ? <Grid item xs={12}>
                            <TextField
                                required
                                id="userName"
                                name="userName"
                                label="User Name"   
                                fullWidth
                                variant="standard"
                                value={item?.userName}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        [e.target.name]: e.target.value
                                    })
                                }}
                                //onChange={setFormData}
                            />
                            <span style={{ color: "red" }}>{errors["userName"]}</span>
                        </Grid> : ''}

                        {item?.type == secretTypesConstants.CreditCards
                            ? <Grid item xs={12}>
                            <TextField
                                id="userLastName"
                                name="userLastName"
                                label="User Last Name"   
                                fullWidth
                                variant="standard"
                                value={item?.userLastName}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        [e.target.name]: e.target.value
                                    })
                                }}
                                //onChange={setFormData}
                            />
                            <span style={{ color: "red" }}>{errors["userLastName"]}</span>
                        </Grid> : ''}

                        {item?.type == secretTypesConstants.CreditCards
                            ? <Grid item xs={12}>
                            <TextField
                                required
                                id="userEmail"
                                name="userEmail"
                                label="User Email"   
                                fullWidth
                                variant="standard"
                                value={item?.userEmail}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        [e.target.name]: e.target.value
                                    })
                                }}
                                //onChange={setFormData}
                            />
                            <span style={{ color: "red" }}>{errors["userEmail"]}</span>
                        </Grid> : ''}

                        <Grid item xs={12}>
                            <TextField
                                id="notes"
                                name="notes"
                                label="Notes"
                                multiline
                                fullWidth
                                variant="standard"
                                value={item?.notes}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        [e.target.name]: e.target.value
                                    })
                                }}
                                //onChange={setFormData}
                            />
                            <span style={{ color: "red" }}>{errors["notes"]}</span>
                        </Grid>
                        <Divider />
                        <Grid item xs={12}>
                            <Button onClick={props.closeForm} variant="contained" color="secondary">Cancel</Button>
                            <Button type="submit"
                                    variant="contained" 
                                    disabled={submitting}
                                    sx={{ml:1}}>
                                {submitting ?
                                    <CircularProgress  size={20} color="secondary" sx={{mr:2}}/>
                                : null}
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
        </form>
    );
};

export default SecretForm;