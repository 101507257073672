import { Button, Divider, Grid, Paper, Skeleton, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import DateTextField from "../components/dateTexField.component";
import apiRequest from "../services/request";
import CloseIcon from '@mui/icons-material/Close';

const SecretDetail = props => {
    const [selectedItem, setSelectedItem] = useState();
    const [loading, setLoading] = useState();

    const sectionItemId = props.selectedItem;

    useEffect(() => {
        if(sectionItemId) {
            setLoading(true);
            apiRequest(`section/${sectionItemId}?$expand=userCreator($select=fullName),userModifier($select=fullName)`)
            .then(response => {
                setSelectedItem(response.data);

                setLoading(false);
            });
        }
    }, [sectionItemId]);

    

    if(!selectedItem || loading) {
        return (
            <>
                <Skeleton height={80} width={250}/>
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} width={120} animation="wave" />
            </>
        );
    }
    return (
        <Paper sx={{p:2}}>        
           <Grid container spacing={3}>
                <Grid item xs={12} flex="inline-flex">
                    <Grid container>
                        <Grid xs={8}>
                            <Typography variant="h6" gutterBottom>
                                Details
                            </Typography>
                        </Grid>
                        <Grid xs={4}>
                            <Button style={{
                                float:'right'
                            }} onClick={props.handleUnselectItem}>
                                <CloseIcon />
                            </Button>
                        </Grid>
                    </Grid>                    
                    <Divider />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="id"
                        name="id"
                        label="Identifier"
                        fullWidth
                        variant="standard"
                        value={selectedItem.id}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="name"
                        name="name"
                        label="Name"
                        fullWidth
                        variant="standard"
                        value={selectedItem.name}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="backgroundColor"
                        name="backgroundColor"
                        label="Background Color"
                        fullWidth
                        variant="standard"
                        value={selectedItem.backgroundColor}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        id="fontColor"
                        name="fontColor"
                        label="Font Color"
                        fullWidth
                        variant="standard"
                        value={selectedItem.fontColor}
                    />
                </Grid>
                
                <DateTextField id="created" name="created" label="Created" value={selectedItem.created}/>
                <DateTextField id="updated" name="updated" label="Updated" value={selectedItem.updated}/>

                {selectedItem.userCreator ? <Grid item xs={12}>
                    <TextField
                        id="userCreator"
                        name="userCreator"
                        label="User Creator"
                        fullWidth
                        variant="standard"
                        value={selectedItem.userCreator?.fullName}
                    />
                </Grid> : ''}

                {selectedItem.userModifier ? <Grid item xs={12}>
                    <TextField
                        id="userModifier"
                        name="userModifier"
                        label="User Modifier"
                        fullWidth
                        variant="standard"
                        value={selectedItem.userModifier?.fullName}
                    />
                </Grid> : ''}

                <Divider />
                <Grid item xs={12}>
                    <Button onClick={props.handleUnselectItem} variant="contained">Close</Button>
                    <Button onClick={props.handleEditItem} variant="contained" sx={{ml:1}} >Edit</Button>
                    <Button onClick={props.handleDeleteItem} variant="contained" sx={{ml:1}} color="error">Delete</Button>
                </Grid>
            </Grid>
        </Paper>
    );
};
export default SecretDetail;