import styled from "@emotion/styled";
import React, { useState,useEffect } from "react";
import Login from "./components/login.component";
import AuthService from "./services/AuthService";
import MuiAppBar from '@mui/material/AppBar';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import Dashboard from "./pages/dashboard";
import { Routes,Route } from "react-router";
import Secrets from "./pages/secrets";
import KeyIcon from '@mui/icons-material/Key';
import ErrorPage from "./pages/error";
import Sections from "./pages/sections";
import Clients from "./pages/clients";
import UserMenu from "./pages/userMenu";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      '& .MuiDrawer-paper': {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: 'border-box',
        ...(!open && {
          overflowX: 'hidden',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: theme.spacing(7),
          [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
          },
        }),
      },
    }),
  );
  
const mdTheme = createTheme();

const App = () => {

    //var moment = require('moment-timezone');
    //moment.tz.setDefault(moment.tz.guess());

    const [currentUser, setCurrentUser] = useState(AuthService.CurrentUser());
    const [sideBarOpen, setSideBarOpen] = React.useState(true);
    const [title, setTitle] = useState("Dashboard");

    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = `${title} - ACE VAULT`;
      }, [title]);

    const toggleDrawer = () => {
        setSideBarOpen(!sideBarOpen);
    };

    if(!currentUser)
        return <Login />;
    
    const handleLogout = () => {
        AuthService.Logout().then(() =>{
            setCurrentUser(null);
        });
    };
    const handleChangeCompany = (access) => {
        AuthService.ChangeCompany(access).then(() =>{
            setCurrentUser(AuthService.CurrentUser());
            window.location.reload();
        });
    };

    return (
        <ThemeProvider theme={mdTheme}>
            <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="absolute" open={sideBarOpen}>
                <Toolbar
                sx={{
                    pr: '24px', // keep right padding when drawer closed
                }}
                >
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                    onClick={toggleDrawer}
                    sx={{
                    marginRight: '36px',
                    ...(sideBarOpen && { display: 'none' }),
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Typography
                    component="h1"
                    variant="h6"
                    color="inherit"
                    noWrap
                    sx={{ flexGrow: 1 }}>
                    {title}
                </Typography>
                
                    <UserMenu handleLogoutProps={handleLogout}
                              handleChangeCompanyProps={handleChangeCompany}
                              currentUserProps={currentUser}/>               
                </Toolbar>                
            </AppBar>
            <Drawer variant="permanent" open={sideBarOpen}>
                <Toolbar
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    px: [1],
                }}
                >
                    <h4>ACE VAULT</h4>
                <IconButton onClick={toggleDrawer}>
                    <ChevronLeftIcon />
                </IconButton>
                </Toolbar>
                <Divider />
                <List className="list-sidebar">
                    <ListItem button component={NavLink} to="/">
                            <ListItemIcon>
                                <DashboardIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                        <ListItem button component={NavLink} to="/secrets">
                            <ListItemIcon>
                                <KeyIcon />
                            </ListItemIcon>
                            <ListItemText primary="Secrets" />
                        </ListItem>
                        <ListItem button component={NavLink} to="/sections">
                            <ListItemIcon>
                                <FilterNoneIcon />
                            </ListItemIcon>
                            <ListItemText primary="Sections" />
                        </ListItem>
                        <ListItem button component={NavLink} to="/clients">
                            <ListItemIcon>
                                <PersonIcon />
                            </ListItemIcon>
                            <ListItemText primary="Clients" />
                        </ListItem>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                backgroundColor: (theme) =>
                    theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[900],
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
                }}
            >
                <Toolbar />
                <Grid sx={{ m: 4 }}>
                    <Routes>
                        <Route exact path="/" element={<Dashboard setTitleProps={setTitle}/>} />
                        <Route exact path="/secrets" element={<Secrets setTitleProps={setTitle} />} />
                        <Route exact path="/sections" element={<Sections setTitleProps={setTitle} />} />
                        <Route exact path="/clients" element={<Clients setTitleProps={setTitle} />} />
                        <Route exact path="*" element={<ErrorPage setTitleProps={setTitle} />} />
                    </Routes>
                </Grid>
            </Box>
            </Box>
        </ThemeProvider>
    );
};

export default App;