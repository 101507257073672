import { string } from "prop-types";
import apiRequest from "./request";
//import { withSnackbar } from 'material-ui-toast'
import jwt_decode from "jwt-decode";

class AuthService {
    Login(params) {
        return apiRequest("/auth/authenticate", {
            method: "POST",
            credentials: "include",
            data: params
        }).then(response => {
            localStorage.setItem("x-auth-token", JSON.stringify(response.data));

            return response;
        }, error => {
            // withSnackbar.show({
            //     message: error
            // })

            return error;
        });
    }

    LoginAzureAd(params) {
        return apiRequest("/auth/ad", {
            method: "POST",
            credentials: "include",
            data: params
        }).then(response => {
            localStorage.setItem("x-auth-token", JSON.stringify(response.data));

            return response;
        }, error => {
            // withSnackbar.show({
            //     message: error
            // })

            return error;
        });
    }
    
    RefreshToken(token) {
        const { accessToken, refreshToken } = typeof(token) === string ? JSON.parse(token) : token;
        return apiRequest("/auth/refreshToken", {
            method: "POST",
            data: { accessToken, refreshToken }
        }).then(response => {
            localStorage.setItem("x-auth-token", JSON.stringify(response.data));

            return response;
        }, error => {
            //console.log("refreshToken", error);

            return error;
        });
    }

    ChangeCompany(access) {
        return apiRequest(`/account/changeCompany?companyId=${access.company.id}&accessUserId=${access.id}`, {
            method: "POST"
        }).then(response => {
            localStorage.setItem("x-auth-token", JSON.stringify(response.data));

            return response;
        }, error => {
            //console.log("refreshToken", error);

            return error;
        });
    }
    
    Logout() {
        return apiRequest("/auth/logout", {
            method: "POST"
        }).then(() =>{
            localStorage.removeItem("x-auth-token");
        });
    }

    CurrentUser() {
        const token = localStorage.getItem('x-auth-token');
        if(token) {
            try {
                return jwt_decode(JSON.parse(token).accessToken);
            } catch(e){
                //
            }
        }
        return null;
    }
}

export default new AuthService();