import {Button,Dialog, DialogActions, DialogContent, DialogTitle} from '@mui/material';
import React from 'react';


const DeleteConfirmDialog = (props) => {
  const { title, children, open, setOpen, onConfirm } = props;
  return (
    <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="confirm-dialog"
      >
        <DialogTitle id="confirm-dialog">{title ?? 'Confirmation'}</DialogTitle>
        <DialogContent>{children ?? 'Are you sure you want to delete item?'}</DialogContent>
        <DialogActions>
          <Button variant="contained" 
                      onClick={() => setOpen(false)}
                      color="error">No</Button>
          <Button variant="contained" onClick={() => {setOpen(false);onConfirm();}} 
                  >Yes</Button>
        </DialogActions>
      </Dialog>
  );
};
export default DeleteConfirmDialog;