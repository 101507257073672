import { Autocomplete, TextField } from "@mui/material";
import React from "react";
import { secretTypes } from "../utils/enums";

const SecretTypeSelect = props => {
    const {
        label,
        selectId,
        required,
        setSelectedItem,
        defaultSelectedItem
    } = props;

    const handleSelectionChange = (event, item) => {
        setSelectedItem(item);
    };
    return (
        <Autocomplete
            id={selectId}
            disableClearable={required}
            defaultValue={defaultSelectedItem === null ? null : secretTypes.find( ({ value }) => value === defaultSelectedItem )}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            options={secretTypes}
            fullWidth
            onChange={(event, newValue) => {
                handleSelectionChange(event, newValue);
            }}
            renderInput={(params) => <TextField {...params} label={label} variant="standard"/>}
        />
    );
};
export default SecretTypeSelect;