import { Button, Divider, Grid, Paper, TextField, Typography, CircularProgress, Slide, Skeleton } from "@mui/material";

import React, { useState, useEffect } from "react";
import apiRequest from "../services/request";
import { isSuccessStatusCode } from "../utils/utils";
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';

const ClientForm = props => {    
    const {enqueueSnackbar} = useSnackbar();
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState();

    const [submitting, setSubmitting] = useState(false);
    const [item, setItem] = useState({});
    
    const editingItem = props.editingItem;

    useEffect(() => {
        if(editingItem) {
            setLoading(true);
            apiRequest(`client/${editingItem}`)
            .then(response => {   
                setItem(response.data);
                
                setLoading(false);
            });
        }
    }, [editingItem]);
    
    const handleSubmit = (event) => {
        event.preventDefault();

        console.log(item);

        setSubmitting(true);
        apiRequest("client", {
            method: !editingItem ? "POST" : "PUT",
            data: item
        })
        .then(response => {
            if(isSuccessStatusCode(response.status)) {
                props.onClientAdd();
            }
            setSubmitting(false);
        }, error => {
            enqueueSnackbar(error.response.data.message, {
                variant:'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                TransitionComponent: Slide,
                autoHideDuration: 3000
            });

            try{
                var validationErrors = error.response.data.validationErrors;
                if(validationErrors instanceof Array){
                    for (let i = 0; i < validationErrors.length; i++) {
                        const element = validationErrors[i];
                        let errorMessages = "";
                        element.errorMessages.forEach(em => {
                            errorMessages += em;
                        });
                        setErrors({
                            ...errors,
                            [element.field]: errorMessages
                        })
                    }
                }
            } catch(e){
                //
            }
            
            setSubmitting(false);
        });
    }

    if(loading) {
        return (
            <>
                <Skeleton height={80} width={250}/>
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} animation="wave" />
                <Skeleton height={50} width={120} animation="wave" />
            </>
        );
    }
    return (
        <form onSubmit={handleSubmit}>
            <Paper sx={{p:2}}>        
                <Grid container spacing={3}>
                        <Grid item xs={12} flex="inline-flex">
                            <Grid container>
                                <Grid item xs={8}>
                                    <Typography variant="h6" gutterBottom>
                                    {editingItem ? "Edit" : "Add"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button style={{
                                        float:'right'
                                    }} onClick={props.closeForm}>
                                        <CloseIcon />
                                    </Button>
                                </Grid>
                            </Grid>                    
                            <Divider />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="name"
                                name="name"
                                label="Name"
                                fullWidth
                                variant="standard"
                                value={item?.name}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        [e.target.name]: e.target.value
                                    })
                                }}
                            />
                            <span style={{ color: "red" }}>{errors["name"]}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                id="identityNumber"
                                name="identityNumber"
                                label="Identity Number"
                                fullWidth
                                variant="standard"
                                value={item?.identityNumber}
                                onChange={(e) => {
                                    setItem({
                                        ...item,
                                        [e.target.name]: e.target.value
                                    })
                                }}
                            />
                            <span style={{ color: "red" }}>{errors["identityNumber"]}</span>
                        </Grid>
                        <Divider />
                        <Grid item xs={12}>
                            <Button onClick={props.closeForm} variant="contained" color="secondary">Cancel</Button>
                            <Button type="submit"
                                    variant="contained" 
                                    disabled={submitting}
                                    sx={{ml:1}}>
                                {submitting ?
                                    <CircularProgress  size={20} color="secondary" sx={{mr:2}}/>
                                : null}
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
        </form>
    );
};

export default ClientForm;