import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import apiRequest from "../services/request";

const AutocompleteServerList = props => {
    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState();

    const {
        entityName, 
        selectQuery,
        defaultSelectedItem,
        label,
        selectId,
        setSelectedItem,
        displayProperty,
        required
    } = props;

    useEffect(() => {
        setLoading(true);
        
        apiRequest(`${entityName}${selectQuery}`)
            .then(response => {
                setItems(response.data);
                setLoading(false);
            });
    }, [entityName,selectQuery]);

    const handleSelectionChange = (event, item) => {
        setSelectedItem(event, item);
    };
    return (
        <Autocomplete
            id={selectId}
            disableClearable={required}
            options={items}
            defaultValue={defaultSelectedItem}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option) => option[displayProperty]}
            fullWidth
            onChange={(event, newValue) => {
                handleSelectionChange(event, newValue);
            }}
            renderInput={(params) => <TextField 
                {...params} 
                label={label} 
                disabled={loading}
                variant="standard"
                required={required}/>}
        />
    );
};
export default AutocompleteServerList;