import { Grid, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardTotalCard from "../components/dashboardTotalCard.component";

import apiRequest from "../services/request";

const Dashboard = props => {
    const [loading, setLoading] = useState();
    const [dashboardTotals, setDashboardTotals] = useState();
    const [dashboardFilters, setDashboardFilters] = useState({
        year: null,
        month: null,
    });

    useEffect(() =>{
        props.setTitleProps("Dashboard");
    });

    useEffect(() => {
        setLoading(true);
        apiRequest(`dashboard`)
        .then(response => {
            setDashboardTotals(response.data?.totals);

            setLoading(false);
        });
    }, [dashboardFilters]);

    if(!dashboardTotals || loading) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Skeleton height={100}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton height={100}/>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton height={100}/>
                </Grid>            
            </Grid>
        );
    }
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
                <DashboardTotalCard title={"Total Secrets"} 
                                    total={dashboardTotals?.secretsCount}
                                    icon="Key"
                                    route="secrets"/>
            </Grid>
            <Grid item xs={12} md={4}>
                <DashboardTotalCard title={"Total Clients"} 
                                    total={dashboardTotals?.clientsCount}
                                    icon="Person"
                                    route="clients"/>
            </Grid>
            <Grid item xs={12} md={4}>
                <DashboardTotalCard title={"Total Sections"} 
                                    total={dashboardTotals?.sectionsCount}
                                    icon="FilterNone"
                                    route="sections"/>
            </Grid>            
        </Grid>
    );
};

export default Dashboard;